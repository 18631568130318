@font-face {
  font-family: 'FM Bolyar Sans Pro';
  src: url('FM/FMBolyarSansPro-900.ttf');
  font-weight: 900;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Ultralight.ttf');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Thin.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Semibold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Heavy.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('SF/SFProDisplay-Black.ttf');
  font-weight: 900;
  font-style: normal;
}
